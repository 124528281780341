<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document-callback'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('게임 실행'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'seamless/authenticate',
      response: '',
      // username, vendorKey, gameKey, ipAddress, lobbyKey, platform, requestKey
      body: {
        username: {type: 'text', value: 'user1', desc: this.$t('text.api.username_desc'), required: true},
        nickname: { type: 'text', value: '회원1', desc: this.$t('nickname'), length: '1~21' },
        language: {type: 'list', list: ['ko','en'], value: 'ko', desc: '게임 언어'},
        vendorKey: {type: 'vendors', value: '', desc: this.$t('text.api.vendorKey_desc'), required: true},
        gameKey: {type: 'games', value: '', desc: this.$t('text.api.gameKey_desc'), required: true},
        lobbyKey: {type: 'text', value: 'top_games', desc: this.$t('text.api.lobbyKey_desc')},
        platform: {type: 'list', list: ['WEB', 'MOBILE'], value: 'WEB', desc: this.$t('text.api.platform_desc')}
      }
    };
  },
  mounted() {
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }

     */
    const host = process.env.VUE_APP_API_SERVER_URL
    this.apiUrl = host + 'seamless/authenticate'

    // 응답값 설정
    this.response = '----------------------------------------------------------------------------\n' +
        '[사이트 콜백 수신값] : 1. API로 게임 실행 요청을 하면 사이트로 회원 정보를 요청합니다.\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        `  apiKey: "${this.$store.getters['auth/user'].apiKey}", // API으로부터 부여 받은 apiKey\n` +
        `  params: { siteUsername: "${this.body.username.value}" }, // API으로 전송한 username \n` +
        '  requestAt: "2022-08-01 00:00:00.000Z"\n' +
        '}\n' +
        '----------------------------------------------------------------------------\n' +
        '[성공 응답값] : 2. 사이트에서는 회원 정보 요청을 받으면 아래의 데이터를 응답합니다.\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        '  code: 0, \n' +
        '  data: \n' +
        '    {\n' +
        `      username: "${this.body.username.value}", // API으로 전송한 username\n` +
        `      siteUsername: "${this.body.username.value}", // API에 저장할 사이트 username\n` +
        '      groupKey: "shop1",// 회원 구분 코드(사이트에서 임의로 지정)\n' +
        '    }\n' +
        '}\n' +
        '----------------------------------------------------------------------------\n' +
        '[실패 응답값]\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        '  code: -1, // 실패코드로서 0이 아닌 아무 값이나 입력하시면 됩니다.\n' +
        '  msg: "NOT_VALID" // 디버깅을 위한 메세지\n' +
        '}\n' +
        '----------------------------------------------------------------------------\n' +
        '[수신값] : 3. API에서 사이트로 게임 URL을 전송하면 게임을 실행합니다.\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        '  code: 0, \n' +
        '  url: "https://solawins-sg0.prerelease-env.biz/gs2c/playGame.do?key=token%3D8537555a09b85028b7e68d6b7337039d%26symbol%3Dvs20sbxmas%26platform%3DWEB%26language%3Dko%26currency%3DKRW%26cashierUrl%3D%26lobbyUrl%3D&stylename=slwn_king&country=KR&minimode=0" // 게임 실행 URL\n' +
        '}'
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-primary">요청 URL</span> {{ apiUrl }}</div>
            <div class="card-title"><span class="badge bg-success">콜백 URL</span> {{ $store.getters['auth/user'].seamlessCallbackUrl.authenticate }}</div>
            <div class="card-title-desc">사이트에서 API으로 게임 실행 요청을 하면 사이트로부터 회원 인증을 받은 후 게임 URL을 전송합니다.</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="true" :require-ip-address="true" :require-request-key="true" :requireSample="true" :require-play="true" :method="'run2'" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
